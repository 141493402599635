// extracted by mini-css-extract-plugin
export var wrapper = "News-module--wrapper--2875d";
export var container = "News-module--container--f3249";
export var news = "News-module--news--8b164";
export var title = "News-module--title--fc8b9";
export var inner = "News-module--inner--da4f8";
export var swiper = "News-module--swiper--c7288";
export var card = "News-module--card--d959c";
export var top = "News-module--top--f3b09";
export var info = "News-module--info--6693d";
export var text = "News-module--text--fab45";
export var description = "News-module--description--2d19f";
export var bottom = "News-module--bottom--fe36f";
export var stats = "News-module--stats--39865";
export var views = "News-module--views--860dd";
export var likes = "News-module--likes--1d9b6";
export var link = "News-module--link--ac14d";