import * as styles from './Invitation.module.scss'
import weter from './assets/weter.svg'
import gorod from './assets/gorod.svg'
import voda from './assets/voda.svg'

import { Button } from '../../widgets'

import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { getTranslate } from 'react-localize-alias'
import { Link } from '@UIKit'

interface InvestProps {
  investorCount: number
}

export const Invitation = ({ investorCount }: InvestProps) => {
  const items = [
    { logo: weter, text: 'W.E.T.E.R' },
    { logo: gorod, text: 'GOROD L.E.S' },
    { logo: voda, text: 'V.O.D.A' },
  ]
  const hostname = typeof window !== 'undefined' ? window.location.hostname : 'tiaglin'
  const domain = hostname !== 'localhost' ? hostname : 'tiaglin'
  return (
    <section className={styles.invitation}>
      <StaticImage
        className={styles.background}
        quality={100}
        placeholder="blurred"
        src={'./assets/bg.png'}
        alt="world map"
      />
      <div className={styles.top}>
        <h2 className={styles.title}>{getTranslate('landing.invitation.title')}</h2>
        <p className={styles.text}>{getTranslate('landing.invitation.description')}</p>
        <div className={styles.count}>{investorCount}</div>

        <Link className={styles.button} to={`https://my.${domain}.com/{code}/`}>
          <Button>{getTranslate('landing.invitation.button1')}</Button>
        </Link>
      </div>
      <article className={styles.projects}>
        {items.map((item, index) => (
          <div className={styles.wrapper} key={index}>
            <div className={styles.card}>
              <img src={item.logo} alt={item.text} />
              <h6 className={styles.name}>{item.text}</h6>
            </div>
          </div>
        ))}
      </article>
      <Link className={styles.button} to={`https://${domain}.com/{code}/`}>
        <Button className={styles.btn}>{getTranslate('landing.invitation.button2')}</Button>
      </Link>
    </section>
  )
}
