// extracted by mini-css-extract-plugin
export var wrapper = "Footer-module--wrapper--afada";
export var container = "Footer-module--container--3845a";
export var footer = "Footer-module--footer--3d9e8";
export var info = "Footer-module--info--275b7";
export var logo = "Footer-module--logo--e22b9";
export var copyright = "Footer-module--copyright--84145";
export var middle = "Footer-module--middle--03e92";
export var text = "Footer-module--text--8b464";
export var link = "Footer-module--link--04908";
export var feedback = "Footer-module--feedback--c668c";
export var social = "Footer-module--social--8f680";
export var mail = "Footer-module--mail--e055a";
export var number = "Footer-module--number--91f8b";