/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useState } from 'react'

import * as styles from './Presentation.module.scss'

import player from './assets/player.svg'
import preview1 from './assets/preview1.png'
import { AppContext } from '@components/App'
import { Language } from '@enum'
import { EmbedVideo } from '@UIKit'
import cn from 'classnames'

const presentations: Record<Language, string> = {
  ar: 'https://drive.google.com/file/d/1xUjg3jZOwnkFewxSUSzzvh5OYeXKzbID/preview',
  bn: 'https://drive.google.com/file/d/1RYbJuEtekA2Nf7ij5UBq3pX7LlbPsD9n/preview',
  en: 'https://drive.google.com/file/d/10NBkOGNLHTg_ZPVscxjV2u8zPLlLieiV/preview',
  fr: 'https://drive.google.com/file/d/1g0BAdmcPDlrHFkBDO-NXrxRepi7oqjQV/preview',
  hi: 'https://drive.google.com/file/d/1WdWJh2e6wBEDXnvG9fdElAnZVZS8GKbd/preview',
  id: 'https://drive.google.com/file/d/1qmiMuTdiNgV23nQ3wUUzdceJeW1IHFvb/preview',
  it: 'https://drive.google.com/file/d/1xzg6Mq7rq6sROm6Ay-Soskq8kHP3WYes/preview',
  ja: 'https://drive.google.com/file/d/1JXaSoZI_-Ly0lvFrIxxnXXdHiEYI2mZc/preview',
  ko: 'https://drive.google.com/file/d/1tQgTsc3_VZcJqx4JvjuZXhozuIzAmBha/preview',
  pt: 'https://drive.google.com/file/d/1TxZGihcYhsfMokgvfoRJsuYOqF2ScxfA/preview',
  ru: 'https://drive.google.com/file/d/1Etv9JSiz87jMDaN8omMSjZRqBUvVeqQd/preview',
  es: 'https://drive.google.com/file/d/1vdjMWKpqCcqBCHUcbnXdjqyDI9HqQrb6/preview',
  vi: 'https://drive.google.com/file/d/1kGF3nPFLqwH9WN1uehDj3fqwU12-2K-Q/preview',
  cn: 'https://drive.google.com/file/d/1Iq4xeyq_h7r84WAmt-CnpXi1MRyboQU6/preview',
}

const Line = () => {
  return <hr className={styles.line} />
}

export const Presentation = () => {
  const { langCode } = useContext(AppContext)
  const [play, setPlay] = useState(false)

  return (
    <section className={cn('container', styles.player)}>
      <div className={styles.presentation}>
        {play ? (
          <EmbedVideo src={presentations[langCode || Language.Default]} />
        ) : (
          <div className={styles.preview}>
            <img className={styles.img} src={preview1} alt="" />
            <button className={styles.btn} onClick={() => setPlay(true)}>
              <img src={player} alt="player" />
            </button>
          </div>
        )}
        <Line />
      </div>
    </section>
  )
}
