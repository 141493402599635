import React, { ReactNode } from 'react'
import cn from 'classnames'

import * as styles from './Badge.module.scss'

interface IBadge {
  className?: string
  children: ReactNode
}

export const Badge = ({ className, children }: IBadge) => {
  const badgeClass = cn(styles.badge, {
    [className!]: Boolean(className),
  })

  return <div className={badgeClass}>{children}</div>
}
