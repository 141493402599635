// extracted by mini-css-extract-plugin
export var wrapper = "Invest-module--wrapper--62bfc";
export var container = "Invest-module--container--8877f";
export var invest = "Invest-module--invest--7bef6";
export var content = "Invest-module--content--4ac87";
export var logo = "Invest-module--logo--902b4";
export var title = "Invest-module--title--6e404";
export var bonus = "Invest-module--bonus--d5d74";
export var time = "Invest-module--time--d2d40";
export var btns = "Invest-module--btns--f62bd";
export var btn = "Invest-module--btn--c3ff9";
export var images = "Invest-module--images--412c2";
export var phone = "Invest-module--phone--bf147";
export var shield = "Invest-module--shield--3ef81";