import { getTranslate } from 'react-localize-alias'

import * as styles from './Goals.module.scss'

import React from 'react'

export const Goals = () => {
  const items = ['roadmap.description1', 'roadmap.description2', 'roadmap.description3']

  return (
    <section className={styles.goals}>
      <h2 className={styles.title}>{getTranslate('landing.goals.title')}</h2>
      <div className={styles.list}>
        {items.map((item, index) => (
          <article className={styles.item} key={index}>
            <hr className={styles.line} />
            <p className={styles.text}>{getTranslate(item)}</p>
          </article>
        ))}
      </div>
    </section>
  )
}
