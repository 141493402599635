import * as styles from './Visualization.module.scss'
import { SwiperSlide } from 'swiper/react'
import { SwiperOptions } from 'swiper/types'

import { Slider } from '../../widgets'

import React, { useState } from 'react'
import { Modal } from '@components/App/Modal'
import { GatsbyImage } from 'gatsby-plugin-image'
import cn from 'classnames'

interface VisualizationProps {
  useVisualizationHook: () => {
    cropped: any[]
    full: any[]
  }
}

export const Visualization = ({ useVisualizationHook }: VisualizationProps) => {
  const [selectedIndex, setSelectedIndex] = useState<null | number>(null)

  const [modal, setModal] = useState(false)

  const { cropped, full } = useVisualizationHook()

  const selectedImage = full[selectedIndex || 0]
  const isVertical =
    selectedImage.childImageSharp.gatsbyImageData.height >= selectedImage.childImageSharp.gatsbyImageData.width

  const sliderConfig: SwiperOptions = {
    spaceBetween: 10,
  }

  const openModal = (index: number) => {
    // swiper?.autoplay.stop()
    setSelectedIndex(index)
    setModal(true)
  }
  const closeModal = () => {
    // swiper?.autoplay.start()
    setModal(false)
  }

  return (
    <>
      <section className={styles.visualization}>
        <Slider config={sliderConfig}>
          {cropped.map(({ name, childImageSharp: { gatsbyImageData } }, index) => (
            <SwiperSlide key={index} className={styles.card} onClick={() => openModal(index)}>
              {/* <img src={item.image} alt="project" /> */}
              <GatsbyImage className={styles.image} key={name} image={gatsbyImageData} alt={name} />
              {/* <h3 className={styles.title}>{item.text}</h3> */}
            </SwiperSlide>
          ))}
        </Slider>
      </section>

      <Modal className={cn(styles.modal, isVertical && styles.vertical)} isOpen={modal} onRequestClose={closeModal}>
        <GatsbyImage image={selectedImage.childImageSharp.gatsbyImageData} alt={selectedImage.name} />
      </Modal>
    </>
  )
}
