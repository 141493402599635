import React from 'react'
import cn from 'classnames'

import * as styles from './Button.module.scss'

interface IButton {
  type?: 'background' | 'main'
  uppercase?: boolean
  className?: string
  children: string
}

export const Button = ({ type = 'main', uppercase, className, children }: IButton) => {
  return (
    <button className={cn(styles.button, styles[type], uppercase && styles.uppercase, className)} type="button">
      {children}
    </button>
  )
}
