import React from 'react'

import * as styles from './Circles.module.scss'

export const Circles = () => {
  return (
    <div className={styles.circles}>
      {Array.from({ length: 5 }).map((_, index, array) => (
        <div
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          style={{ top: `${(80 / (array.length - 1)) * index}%` }}
          className={`${styles.circle} ${index % 2 ? styles.circleRight : styles.circleLeft}`}
        />
      ))}
    </div>
  )
}
