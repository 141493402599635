/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from 'react'

import * as styles from './Committee.module.scss'

import face from './assets/face.png'
import flag from './assets/flag.svg'

import FacebookIcon from '../../../../../assets/images/weter/facebookIcon.inline.svg'
import InstagramIcon from '../../../../../assets/images/weter/instagramIcon.inline.svg'
import TelegramIcon from '../../../../../assets/images/weter/telegramIcon.inline.svg'
import TwitterIcon from '../../../../../assets/images/weter/twitterIcon.inline.svg'
import VkIcon from '../../../../../assets/images/weter/vkIcon.inline.svg'
import LinkedinIcon from '../../../../../assets/images/weter/linkedinIcon.inline.svg'

import { CommitteeMember, useCommittee, useCommitteeLegacy } from '@graphql'
import { getRandomElements, priorityCountries } from '@utils'
import { Face } from '@components/sections'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { Modal } from '@components/App/Modal'
import { Hexagon, Link, Typography } from '@UIKit'
import { getTranslate } from 'react-localize-alias'

const DISPLAYED_COMMITTEES_NUMBER = 72

const socialMediaIcons = {
  facebook: <FacebookIcon className={styles.icon} />,
  instagram: <InstagramIcon className={styles.icon} />,
  telegram: <TelegramIcon className={styles.icon} />,
  twitter: <TwitterIcon className={styles.icon} />,
  vk: <VkIcon className={styles.icon} />,
  linkedin: <LinkedinIcon className={styles.icon} />,
}

export const Committee = () => {
  const committee = useCommittee()
  const committeeLegacy = useCommitteeLegacy()
  const combinedCommittee = [...committee, ...committeeLegacy]
  const [displayedCommittee, setDisplayedCommittee] = useState<CommitteeMember[]>([])

  const outerContentRef = useRef<HTMLDivElement>(null)
  const innerContentRef = useRef<HTMLDivElement>(null)

  const [modal, setModal] = useState(false)
  const [selected, setSelected] = useState(displayedCommittee[0])

  const handleMember = (member: CommitteeMember) => {
    setSelected(member)
    setModal(true)
  }

  useEffect(() => {
    if (outerContentRef.current && innerContentRef.current) {
      const outerContent = outerContentRef.current
      const innerContent = innerContentRef.current
      outerContent.scrollLeft = (innerContent.scrollWidth - outerContent.clientWidth) / 2
    }

    const priorities = combinedCommittee.filter((el) => priorityCountries.includes(el.country))
    const others = combinedCommittee.filter((el) => !priorityCountries.includes(el.country))
    const shuffledOthers = getRandomElements(others, DISPLAYED_COMMITTEES_NUMBER - priorities.length)

    setDisplayedCommittee(getRandomElements([...priorities, ...shuffledOthers], DISPLAYED_COMMITTEES_NUMBER))
  }, [])

  return (
    <section className={styles.committee}>
      <h2 className={styles.title}>{getTranslate('landing.committee.title')}</h2>
      <p className={styles.text}>{getTranslate('landing.committee.description')}</p>
      <div className={styles.wrapper} ref={outerContentRef}>
        <div className={styles.faces} ref={innerContentRef}>
          {displayedCommittee.map((committeeMember, index) => {
            const { name, country, image } = committeeMember
            return (
              <div key={index} className={styles.face} onClick={() => handleMember(committeeMember)}>
                <Face name={name} image={image} country={country} />
              </div>
            )
          })}
        </div>
      </div>

      {selected && (
        <Modal className={styles.modal} isOpen={modal} onRequestClose={() => setModal(false)}>
          <div className={styles.content}>
            <GatsbyImage className={styles.avatar} image={selected.image} alt={selected.name} />
            <div className={styles.info}>
              <div>
                <Typography disableLocalize size={32}>
                  {selected.name}
                </Typography>
                <Typography className={styles.country} missOnAlias size={20}>
                  {`country.${selected.country}`}
                </Typography>
              </div>
              <div>
                {/* {selected.socialMedia.map(({ type, url }, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Link className={styles.social} key={index} to={url} target="_blank">
                    <Hexagon size={52} color="dark" cursor="pointer">
                      {socialMediaIcons[type as keyof typeof socialMediaIcons]}
                    </Hexagon>
                  </Link>
                ))} */}
              </div>
            </div>
          </div>
        </Modal>
      )}
    </section>
  )
}
