/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import * as styles from './SliderButton.module.scss'
import arrowLeft from './assets/arrowLeft.png'
import arrowRight from './assets/arrowRight.png'
import { useSwiper } from 'swiper/react'
import { DirectionsEnum } from '../../types'

interface ISwiperButton {
  direction: DirectionsEnum
}

export const SliderButton = ({ direction }: ISwiperButton) => {
  const swiper = useSwiper()
  const isLeft = direction === DirectionsEnum.LEFT

  const onClick = () => (isLeft ? swiper.slidePrev() : swiper.slideNext())

  return (
    <div className={styles[direction]} onClick={onClick}>
      {isLeft ? <FontAwesomeIcon icon={faChevronLeft} /> : <FontAwesomeIcon icon={faChevronRight} />}
    </div>
  )
}
