import React from 'react'
import cn from 'classnames'

import * as styles from './PatentCountries.module.scss'

import Clock from '@assets/images/weter/clock.inline.svg'
import ShieldCheck from '@assets/images/weter/shieldCheck.inline.svg'

import { Flag } from '@UIKit'
import { Translate } from 'react-localize-alias'
import { Patent } from '@graphql'

interface PatentListProps {
  title: string
  list: Patent[]
  variant: 'pending' | 'confirmed'
  gorodStyle?: boolean
  onClick: (country: Patent) => void
}

export const PatentCountries: React.FC<PatentListProps> = ({ title, list, variant, gorodStyle, onClick }) => {
  const isPending = variant === 'pending'

  return (
    <div className={styles.root}>
      <div className={styles.title}>
        <Translate id={title} />
      </div>
      <div className={styles.countries}>
        {list.map((patent) => (
          <div key={patent.code} className={cn(styles.country)} onClick={() => onClick(patent)}>
            <div className={cn(styles.flag, !gorodStyle && isPending && styles.opacity)}>
              <Flag country={patent.code} className={cn(styles.svg)} />
            </div>

            <div className={styles.name}>
              <Translate id={`country.${patent.code}`} missOnAlias />
            </div>
            {!gorodStyle && (
              <div className={styles.status}>
                {isPending ? <Clock className={styles.clock} /> : <ShieldCheck className={styles.clock} />}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}
