// extracted by mini-css-extract-plugin
export var wrapper = "RoadMap-module--wrapper--773b7";
export var container = "RoadMap-module--container--257bf";
export var roadmap = "RoadMap-module--roadmap--311a0";
export var title = "RoadMap-module--title--aaafa";
export var main = "RoadMap-module--main--5a705";
export var gradient = "RoadMap-module--gradient--00157";
export var plan = "RoadMap-module--plan--676b8";
export var description = "RoadMap-module--description--5fdc4";
export var date = "RoadMap-module--date--f5ecd";
export var text = "RoadMap-module--text--09b17";
export var btn = "RoadMap-module--btn--1c2b4";