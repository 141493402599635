/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'

import * as styles from './News.module.scss'

import { SwiperSlide } from 'swiper/react'
import { SwiperOptions } from 'swiper/types'
import { Badge, Slider } from '../../widgets'
import { getTranslate } from 'react-localize-alias'

import { faHeart, faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { News as INews } from '@graphql'
import { GatsbyImage } from 'gatsby-plugin-image'
import dayjs from 'dayjs'
import { getContent } from '@utils'

const sliderConfig: SwiperOptions = {
  breakpoints: {
    320: { spaceBetween: 10 },
    480: { spaceBetween: 30 },
  },
  centeredSlides: false,
}

interface NewsProps {
  setSelectedNews: (index: number) => void
  openNewsModal: () => void
  news: INews[]
}

export const News = ({ setSelectedNews, openNewsModal, news }: NewsProps) => {
  const handleNewsCardClick = (index: number) => {
    setSelectedNews(index)
    openNewsModal()
  }

  return (
    <section className={styles.news}>
      <h2 className={styles.title}>{getTranslate('landing.news.title')}</h2>
      <div className={styles.inner}>
        <Slider config={sliderConfig}>
          {news.map(({ id, mainImage, publishedAt, title, shortDescription, viewsCount, likesCount }, index) => (
            <SwiperSlide className={styles.card} key={id} onClick={() => handleNewsCardClick(index)}>
              <div className={styles.top}>
                <GatsbyImage className={styles.img} image={mainImage} alt="" />
                <Badge>{dayjs(publishedAt).format('DD MMM YYYY')}</Badge>
              </div>
              <div className={styles.info}>
                <h3 className={styles.text}>{getContent(title)}</h3>
                <p className={styles.description}>{getContent(shortDescription)}</p>
              </div>
              <div className={styles.bottom}>
                <div className={styles.stats}>
                  <div className={styles.views}>
                    <FontAwesomeIcon icon={faEye} />
                    <div>{viewsCount}</div>
                  </div>
                  <div className={styles.likes}>
                    <FontAwesomeIcon icon={faHeart} />
                    <div>{likesCount}</div>
                  </div>
                </div>
                <div className={styles.link}>{getTranslate('landing.news.read')} ›</div>
              </div>
            </SwiperSlide>
          ))}
        </Slider>
      </div>
    </section>
  )
}
