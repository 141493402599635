import React, { ReactElement, ReactNode, useState } from 'react'

import * as styles from './Stats.module.scss'

export interface TabItemProps {
  label: string
  children: ReactNode
}

export interface TabListProps {
  children: ReactElement<TabItemProps>[]
}

export const TabItem = ({ label, children }: TabItemProps) => (
  <div role="tabpanel" id={`panel-${label}`}>
    {children}
  </div>
)

export const TabList = ({ children }: TabListProps) => {
  const [activeTab, setActiveTab] = useState(0)

  const handleTabClick = (index: number) => {
    setActiveTab(index)
  }

  return (
    <div className={styles.tabs}>
      <nav className={styles.tabsNav}>
        <ul className={styles.tabsList} role="tablist" aria-orientation="horizontal">
          {children.map((tab, index) => (
            <li key={index}>
              {/* eslint-disable-next-line jsx-a11y/role-supports-aria-props */}
              <button
                aria-selected={activeTab === index}
                onClick={() => handleTabClick(index)}
                className={`${styles.tabsBtn} ${activeTab === index ? styles.tabsBtnActive : ''}`}
              >
                {tab.props.label}
              </button>
            </li>
          ))}
        </ul>
      </nav>
      {children[activeTab]}
    </div>
  )
}
