import React from 'react'
import { getTranslate } from 'react-localize-alias'

import { Link } from '@UIKit'

import * as styles from './Footer.module.scss'

import weterLogo from './assets/weter.svg'
import gorodLogo from './assets/gorod.svg'
import vodaLogo from './assets/voda.svg'

interface FooterProps {
  variant: 'weter' | 'gorod' | 'voda'
}

const logos = {
  weter: weterLogo,
  gorod: gorodLogo,
  voda: vodaLogo,
}

export const Footer = ({ variant }: FooterProps) => {
  return (
    <footer className={styles.footer}>
      <div className={styles.info}>
        <div className={styles.logo}>
          <img src={logos[variant]} alt="Company Logo" />
        </div>
        <address className={styles.copyright}>
          Copyright © 2022 Weter LLC.
          <br />
          All rights reserved. Tiaglin Hub,
          <br />
          UAE, Dubai, DIP 1, W10
        </address>
      </div>

      <div className={styles.middle}>
        <p className={styles.text}>{getTranslate('footer.project.founded')}</p>
        <a href="/" className={styles.link}>
          Terms & privacy policy
        </a>
      </div>

      <div className={styles.feedback}>
        <div className={styles.social}>
          <i className="fa-brands fa-instagram fa-2xl" />
          <i className="fa-brands fa-telegram fa-2xl" />
          <i className="fa-brands fa-whatsapp fa-lg" />
        </div>

        <Link className={styles.mail} to={'mailto:info@tiaglin.com'}>
          info@tiaglin.com
        </Link>

        <Link className={styles.number} to={'tel:+9710585304380'}>
          +971 058 530 4380
        </Link>
      </div>
    </footer>
  )
}
