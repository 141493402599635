/* eslint-disable @typescript-eslint/no-unused-vars */
import * as styles from './Invest.module.scss'
import investLogo from './assets/logo.svg'
import investPhone from './assets/phone.png'
import investShield from './assets/shield.png'
import { Button } from '../../widgets'

import React, { useEffect, useState } from 'react'
import { getTranslate } from 'react-localize-alias'
import dayjs from 'dayjs'
import { Link } from '@UIKit'

export const Invest = () => {
  const [timeLeft, setTimeLeft] = useState(86256000)

  useEffect(() => {
    // Check if there is a stored start time in localStorage
    let startTime: any = localStorage.getItem('startTime')

    if (!startTime) {
      // If no start time, set it to now and save in localStorage
      startTime = new Date().getTime()
      localStorage.setItem('startTime', startTime)
    } else {
      // If there is a start time, parse it as an integer
      startTime = parseInt(startTime, 10)
    }

    // Calculate the time left
    const calculateTimeLeft = () => {
      const now = new Date().getTime()
      const timeDifference = 24 * 60 * 60 * 1000 // 24 hours in milliseconds
      const elapsedTime = now - startTime

      if (elapsedTime >= timeDifference) {
        // If more than 24 hours have passed, reset the start time
        localStorage.setItem('startTime', String(now))
        return 0
      }

      return timeDifference - elapsedTime
    }

    // Update the time left every second
    const timer = setInterval(() => {
      const timeLeft = calculateTimeLeft()
      setTimeLeft(timeLeft)
    }, 1000)

    // Clean up the interval on unmount
    return () => clearInterval(timer)
  }, [])

  // Format the time left into hours, minutes, and seconds
  const formatTime = (milliseconds: number) => {
    const hours = Math.floor((milliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      .toString()
      .padStart(2, '0')
    const minutes = Math.floor((milliseconds % (1000 * 60 * 60)) / (1000 * 60))
      .toString()
      .padStart(2, '0')
    const seconds = Math.floor((milliseconds % (1000 * 60)) / 1000)
      .toString()
      .padStart(2, '0')

    return `${hours}:${minutes}:${seconds}`
  }

  return (
    <section className={styles.invest}>
      <article className={styles.content}>
        <div className={styles.logo}>
          <img src={investLogo} alt="invest logo" />
        </div>
        <div className={styles.title}>
          {getTranslate('landing.invest.title')}
          <span className={styles.bonus}>{getTranslate('landing.invest.description')}</span>
        </div>
        <div className={styles.time}>{formatTime(timeLeft)}</div>
        <nav className={styles.btns}>
          <Link to="https://my.tiaglin.com/{code}/auth" allowEn target="_blank">
            <Button className={styles.btn}>{getTranslate('landing.invest.register')}</Button>
          </Link>

          <Link to="https://t.me/WETER_SUPPORT" allowEn target="_blank">
            <Button className={styles.btn} type="background">
              {getTranslate('landing.invest.consult')}
            </Button>
          </Link>
        </nav>
      </article>

      <aside className={styles.images}>
        <img className={styles.phone} src={investPhone} alt="invest group" />
        <img className={styles.shield} src={investShield} alt="invest group" />
      </aside>
    </section>
  )
}
