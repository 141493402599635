/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'

import { Root as RootOld } from '../../common/pages/weter'
import { Root } from '../../common/pages/new/weter'

const IndexPage = () => {
  return (
    <>
      <Root />
      {/* <RootOld /> */}
    </>
  )
}

export default IndexPage
