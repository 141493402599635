import * as styles from './Compare.module.scss'
import bahrain from './assets/bahrain.png'
import china from './assets/china.png'
import weter from './assets/weter.png'
import bahrainLogo from './assets/bahrainLogo.png'
import chinaLogo from './assets/chinaLogo.png'
import weterLogo from './assets/weterLogo.png'

import React from 'react'
import { getTranslate } from 'react-localize-alias'

export const Compare = () => {
  const items = [
    { image: bahrain, name: getTranslate('comparison.1.title'), logo: bahrainLogo, percent: '3' },
    { image: china, name: getTranslate('comparison.2.title'), logo: chinaLogo, percent: '10' },
    { image: weter, name: 'W.E.T.E.R', logo: weterLogo, percent: '100' },
  ]

  return (
    <section className={styles.compare}>
      <h2 className={styles.title}>{getTranslate('comparison.title')}</h2>
      <div className={styles.slider}>
        {items.map((item, index) => (
          <article className={styles.card} key={index}>
            <img className={styles.image} src={item.image} alt={item.name} />
            <div className={styles.top}>
              <div className={styles.percent}>
                {item.percent}
                <span className={styles.symbol}>%</span>
              </div>
              <span className={styles.text}>{getTranslate('comparison.card')}</span>
            </div>
            <div className={styles.info}>
              <img src={item.logo} alt={item.name} />
              <div className={styles.name}>{item.name}</div>
            </div>
          </article>
        ))}
      </div>
    </section>
  )
}
