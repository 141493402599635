// extracted by mini-css-extract-plugin
export var wrapper = "Invitation-module--wrapper--c4638";
export var container = "Invitation-module--container--427a5";
export var invitation = "Invitation-module--invitation--34a3a";
export var background = "Invitation-module--background--fbce0";
export var top = "Invitation-module--top--d78d9";
export var title = "Invitation-module--title--4bb74";
export var text = "Invitation-module--text--863d3";
export var count = "Invitation-module--count--70601";
export var projects = "Invitation-module--projects--d9de6";
export var card = "Invitation-module--card--8f1bf";
export var name = "Invitation-module--name--3c27e";
export var btn = "Invitation-module--btn--41a85";