// extracted by mini-css-extract-plugin
export var wrapper = "Hero-module--wrapper--05916";
export var container = "Hero-module--container--9a3e9";
export var hero = "Hero-module--hero--ef39d";
export var inner = "Hero-module--inner--20b57";
export var info = "Hero-module--info--17b49";
export var logo = "Hero-module--logo--f739f";
export var title = "Hero-module--title--b02dc";
export var line = "Hero-module--line--59adb";
export var infoText = "Hero-module--infoText--ec9ad";
export var card = "Hero-module--card--7fe02";
export var date = "Hero-module--date--e4f93";
export var main = "Hero-module--main--a8661";
export var mainText = "Hero-module--mainText--77350";
export var bottom = "Hero-module--bottom--c5561";
export var stats = "Hero-module--stats--b56e9";
export var views = "Hero-module--views--6cee7";
export var likes = "Hero-module--likes--9ce61";
export var link = "Hero-module--link--3f98a";
export var navigation = "Hero-module--navigation--bb98c";
export var prev = "Hero-module--prev--293be";
export var next = "Hero-module--next--097de";
export var navLine = "Hero-module--navLine--6ab51";
export var img = "Hero-module--img--c5345";