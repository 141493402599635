/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faAngleDown } from '@fortawesome/free-solid-svg-icons'

import * as styles from './Header.module.scss'

import TelegramIcon from '../../../../../assets/images/weter/telegramIcon.inline.svg'
import InstagramIcon from '../../../../../assets/images/weter/instagramIcon.inline.svg'
import { getTranslate } from 'react-localize-alias'
import { AppContext, LanguageSelect } from '@components/App'
import { List, Typography, Link } from '@UIKit'
import { replaceLink } from '@utils'
import { Img } from 'react-image'
import { useLanguages } from '@graphql'
import cn from 'classnames'

export const Header = () => {
  const allLanguages = useLanguages()
  const { langCode } = useContext(AppContext)

  const [isHidden, setIsHidden] = useState(true)

  const hostname = typeof window !== 'undefined' ? window.location.hostname : 'tiaglin'
  const domain = hostname !== 'localhost' ? hostname : 'tiaglin'

  return (
    <header className={styles.header}>
      <div className={styles.menu}>
        <FontAwesomeIcon icon={faBars} size="xl" />
      </div>
      <nav className={styles.nav}>
        <ul>
          <li className={styles.navItem}>
            <Link to={`https://${domain}.com/{code}/`}>{getTranslate('landing.header.main')}</Link>
          </li>
          <li className={styles.navItem}>
            <Link to={`https://my.${domain}.com/{code}/auth`}>{getTranslate('landing.header.cabinet')}</Link>
          </li>
        </ul>
      </nav>
      <div className={styles.right}>
        <div className={styles.social}>
          <TelegramIcon />
          <InstagramIcon />
          {/* <FontAwesomeIcon icon={faTeletype} size="2xl" />
          <FontAwesomeIcon icon={faTeletype} size="lg" /> */}
          {/* <i className="fa-brands fa-telegram fa-2xl" />
          <i className="fa-brands fa-whatsapp fa-lg" /> */}
        </div>
        {/* <LanguageSelect className={styles.lang} /> */}
        <div
          className={styles.lang}
          onClick={() => {
            setIsHidden(!isHidden)
          }}
        >
          <span className={styles.name}>{langCode}</span>
          <FontAwesomeIcon icon={faAngleDown} />

          <List
            className={cn(styles.list, isHidden && styles.hide)}
            value={langCode}
            options={allLanguages.map(({ icon, code, language }) => ({
              value: code,
              label: (
                <Link
                  key={code}
                  to={() => {
                    return replaceLink('/{code}', code)
                  }}
                  className={styles.listItem}
                >
                  <Img className={styles.flag} src={icon} />
                  {language}
                </Link>
              ),
            }))}
          />
        </div>
      </div>
    </header>
  )
}
