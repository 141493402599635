import { getTranslate } from 'react-localize-alias'
import * as styles from './Tech.module.scss'
import tech1 from './assets/tech1.png'
import tech2 from './assets/tech2.png'
import tech3 from './assets/tech3.png'

import React from 'react'

export const Tech = () => {
  const items = [
    { image: tech1, name: 'technology.shields.description' },
    { image: tech2, name: 'technology.flow.description' },
    { image: tech3, name: 'technology.module.description' },
  ]

  return (
    <section className={styles.tech}>
      <h2 className={styles.title}>{getTranslate('technology.title')}</h2>
      <div className={styles.slider}>
        {items.map((item, index) => (
          <article className={styles.card} key={index}>
            <img src={item.image} alt={item.name} />
            <div className={styles.info}>
              <hr className={styles.line} />
              <p className={styles.name}>{getTranslate(item.name)}</p>
            </div>
          </article>
        ))}
      </div>
    </section>
  )
}
