import React from 'react'

import * as styles from './Header.module.scss'

import LogoIcon from '../../../../../assets/images/weter.inline.svg'
import BrandIcon from '../../../../../assets/images/weter/weter.inline.svg'

import { Link, Typography } from '@UIKit'
import { LanguageSelect } from '@components/App'
import cn from 'classnames'

const logoIconProps = <LogoIcon width={40} height={40} />
const brandIconProps = <BrandIcon />
interface HeaderProps {
  logoIcon?: React.ReactNode
  brandIcon?: React.ReactNode
}

export const Header: React.FC<HeaderProps> = ({ logoIcon = logoIconProps, brandIcon = brandIconProps }) => {
  const hostname = typeof window !== 'undefined' ? window.location.hostname : 'tiaglin'
  const domain = hostname !== 'localhost' ? hostname : 'tiaglin'

  return (
    <header className={styles.root}>
      <div className={styles.wrapper}>
        <div className={styles.logo}>
          {logoIcon}
          {brandIcon}
        </div>
      </div>
      <nav className={styles.list}>
        <Link className={styles.item} to={`https://${domain}.com/{code}/`}>
          <Typography size={18} color={'white'}>
            landing.header.main
          </Typography>
        </Link>
        <Link className={cn(styles.item, styles.cab)} to={`https://my.${domain}.com/{code}/auth`} allowEn>
          <Typography size={18} color={'white'}>
            landing.header.cabinet
          </Typography>
        </Link>
        <LanguageSelect className={styles.language} />
      </nav>
    </header>
  )
}
