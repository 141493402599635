// extracted by mini-css-extract-plugin
export var wrapper = "Committee-module--wrapper--a51fd";
export var container = "Committee-module--container--4a467";
export var committee = "Committee-module--committee--c39b2";
export var title = "Committee-module--title--ecd2a";
export var text = "Committee-module--text--a76b5";
export var faces = "Committee-module--faces--b6e05";
export var face = "Committee-module--face--892a0";
export var image = "Committee-module--image--51f45";
export var flag = "Committee-module--flag--47a27";
export var subtitle = "Committee-module--subtitle--e7392";
export var hexagon = "Committee-module--hexagon--17f8f";
export var modal = "Committee-module--modal--3f0b6";
export var content = "Committee-module--content--23913";
export var avatar = "Committee-module--avatar--0bbac";
export var info = "Committee-module--info--20e27";
export var icon = "Committee-module--icon--4a1ce";
export var group = "Committee-module--group--9aa8b";
export var scroll = "Committee-module--scroll--b4e58";
export var country = "Committee-module--country--083df";
export var social = "Committee-module--social--7fb31";