import { getTranslate } from 'react-localize-alias'
import * as styles from './Rights.module.scss'
import shield from './assets/shield.png'

import React from 'react'

export const Rights = () => {
  return (
    <section className={styles.rights}>
      <img className={styles.shield} src={shield} alt="Shield" />
      <div className={styles.inner}>
        <h3 className={styles.title}>
          {getTranslate('landing.patents.title')}
        </h3>
        <p className={styles.text}>
          {getTranslate('landing.patents.description1')}
        </p>
        <p className={styles.description}>
          {getTranslate('landing.patents.description2')}
        </p>
      </div>
    </section>
  )
}
