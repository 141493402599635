// extracted by mini-css-extract-plugin
export var wrapper = "Compare-module--wrapper--9c130";
export var container = "Compare-module--container--89bb4";
export var compare = "Compare-module--compare--e24b2";
export var title = "Compare-module--title--a9d6c";
export var slider = "Compare-module--slider--488c2";
export var card = "Compare-module--card--960e2";
export var image = "Compare-module--image--bc608";
export var top = "Compare-module--top--78d1e";
export var percent = "Compare-module--percent--0f6cf";
export var symbol = "Compare-module--symbol--8febb";
export var text = "Compare-module--text--fef2d";
export var info = "Compare-module--info--c9d60";
export var name = "Compare-module--name--53e1a";