import React, { ReactNode } from 'react'

import * as styles from './Slider.module.scss'

import { SliderButton } from '../index.ts'
import { DirectionsEnum } from '../../types'
import { Swiper } from 'swiper/react'
import { SwiperOptions } from 'swiper/types'

interface ISlider {
  config: SwiperOptions
  children: ReactNode
}

export const Slider = ({ config, children }: ISlider) => {
  return (
    <div className={styles.slider}>
      <Swiper slidesPerView={'auto'} loop centeredSlides {...config}>
        <SliderButton direction={DirectionsEnum.LEFT} />
        {children}
        <SliderButton direction={DirectionsEnum.RIGHT} />
      </Swiper>
    </div>
  )
}
