/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react'

import { Stream } from '@components/sections'
import { Wrapper } from '@components/App/Wrapper'

import * as styles from './Streams.module.scss'
import { Typography } from '@UIKit'
import { getTranslate } from 'react-localize-alias'
import { weterStreams } from 'src/common/pages/new/weter'

interface StreamsProps {
  streams: {
    id: number
    name: string
    preview: string
    description: string
    address: string
    timezone: number
    time?: string
    url: string
    live: boolean
    technical: boolean
  }[]
}

export const Streams: React.FC<StreamsProps> = ({ streams }) => {
  const [selectedStream, setSelectedStream] = useState(streams[0].id)

  const handleStream = (id: number) => {
    setSelectedStream(id)
  }

  return (
    <section>
      <Wrapper>
        <Typography className={styles.title} size={64} weight={500} color="default">
          landing.streams.title
        </Typography>
        <div className={styles.grid}>
          {streams.map((stream) => (
            <Stream key={stream.id} {...stream} active={selectedStream === stream.id} onClick={handleStream} />
          ))}
        </div>
      </Wrapper>
    </section>
  )
}
