import * as styles from './RoadMap.module.scss'
import gradient from './assets/gradient.svg'

import { Button } from '../../widgets'

import React from 'react'
import { getTranslate } from 'react-localize-alias'
import { Link } from '@UIKit'

export const RoadMap = () => {
  const items = [
    { date: 'roadmap.step1.date', text: 'roadmap.step1.title' },
    { date: 'roadmap.step2.date', text: 'roadmap.step2.title' },
    { date: 'roadmap.step3.date', text: 'roadmap.step3.title' },
    { date: 'roadmap.step4.date', text: 'roadmap.step4.title' },
  ]

  return (
    <section className={styles.roadmap}>
      <h2 className={styles.title}>{getTranslate('landing.roadmap.title')}</h2>
      <main className={styles.main}>
        <img src={gradient} className={styles.gradient} alt="gradient" />
        <div className={styles.plan}>
          {items.map((item, index) => (
            <div key={index} className={styles.description}>
              <h3 className={styles.date}>{getTranslate(item.date)}</h3>
              <p className={styles.text}>{getTranslate(item.text)}</p>
            </div>
          ))}
        </div>
      </main>
      <Link to="https://my.tiaglin.com/project/vod/development" allowEn target="_blank" color="primary">
        <Button className={styles.btn}>{getTranslate('landing.roadmap.button')}</Button>
      </Link>
    </section>
  )
}
