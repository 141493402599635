// extracted by mini-css-extract-plugin
export var video = "Root-module--video--a5ddf";
export var videoMap = "Root-module--videoMap--44a8c";
export var news = "Root-module--news--4dac9";
export var text = "Root-module--text--f8fe2";
export var title = "Root-module--title--5e58d";
export var content = "Root-module--content--88ac6";
export var form = "Root-module--form--77756";
export var wrapper = "Root-module--wrapper--dd607";
export var subtitle = "Root-module--subtitle--3e75b";
export var inputs = "Root-module--inputs--bff5c";
export var links = "Root-module--links--77ffa";
export var item = "Root-module--item--7dab2";
export var icon = "Root-module--icon--fe3db";
export var social = "Root-module--social--aaedc";